import React, { useState } from "react"
import {
  Container,
  TextField,
  CircularProgress,
  Button,
  Grid,
  Typography,
  makeStyles,
  Paper,
  CardMedia,
  CardContent,
  Card,
} from "@material-ui/core"
import Hero from "../components/hero"
import axios from "axios"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Achieve from "../img/achieve.jpg"
import SchoolWay from "../img/astonsway.jpg"

const useStyles = makeStyles(theme => ({
  submissionText: {
    fontSize: "1.2rem",
    fontFamily: "Nunito",
    padding: "50px",
    textAlign: "center",
  },
  bottom: {
    paddingBottom: "20px",
  },
  mainPaper: {
    backgroundColor: "#ffffffdd",
  },
  bodyText: {
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    textAlign: "left",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  cardCaption: {
    fontFamily: "Open Sans",
    textAlign: "center",
  },
  card: {
    borderRadius: "25px",
  },
}))

function AstonsWayForm() {
  const classes = useStyles()
  const [name, setName] = useState("")
  const [number, setNumber] = useState("")
  const [groupName, setGroupName] = useState("")
  const [text, setText] = useState("")
  const [formState, setFormState] = useState("initial")

  const LinkCheck = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  const numCheck = /^\d{11}$/
  let responseObj = null

  function handleSubmit() {
    setFormState("loading")
    axios
      .post(
        "https://gi9tdplnub.execute-api.us-east-1.amazonaws.com/default/astonsway",
        {
          name: name,
          number: number,
          groupName: groupName,
          text: text,
          key: "astonsway",
        }
      )
      .then(
        response => {
          responseObj = response.data
          if (responseObj.status == "success") {
            setFormState("done")
          } else {
            setFormState("error")
          }
        },
        error => {
          setFormState("error")
        }
      )
  }

  if (formState == "loading") {
    return (
      <Grid container spacing={0} alignItems="center" justify="center">
        <br />
        <CircularProgress size={150} thickness={1.5} />
        <br />
      </Grid>
    )
  } else if (formState == "error") {
    if (responseObj) {
      return (
        <div>
          <hr />
          <Typography className={classes.submissionText}>
            Submission Error.{responseObj.info}
          </Typography>
        </div>
      )
    } else {
      return (
        <div>
          <hr />
          <Typography className={classes.submissionText}>
            Submission Error. You may have made too many requests. Please try
            again later.
          </Typography>
        </div>
      )
    }
  } else if (formState == "done") {
    return (
      <Grid container spacing={0} alignItems="center" justify="center">
        <br />
        <Typography className={classes.submissionText}>
          Thank you for your submission! Your email has been sent to the team.
        </Typography>
        <br />
      </Grid>
    )
  } else {
    return (
      <Container>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="pamper-name"
          label="Your Name"
          name="pamper-name"
          error={LinkCheck.test(name)}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <br />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="num-1"
          label="Your Mobile (without spaces)"
          name="num-1"
          error={!numCheck.test(number)}
          helperText="Please enter a valid UK mobile number (without spaces)."
          value={number}
          onChange={e => setNumber(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="group-name"
          label="Group/School Name"
          name="group-name"
          error={LinkCheck.test(groupName)}
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
        />
        <br />
        <TextField
          variant="outlined"
          required
          fullWidth
          id="general-text"
          label="About your Group/School"
          placeholder="Your message here..."
          multiline
          rows={8}
          margin="normal"
          error={LinkCheck.test(text)}
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <br />
        <div className={classes.bottom}>
          {name && number && groupName && text ? (
            <Button
              className={classes.submit}
              variant="contained"
              style={{
                backgroundColor: "#0072ab",
                color: "white",
                borderRadius: "50px",
              }}
              color="primary"
              onClick={() => handleSubmit()}
            >
              Submit Form
            </Button>
          ) : (
            <Button
              className={classes.submit}
              variant="contained"
              style={{
                borderRadius: "50px",
              }}
              color="primary"
              disabled
            >
              Enter missing details to submit
            </Button>
          )}
        </div>
      </Container>
    )
  }
}

export default function AstonsWay() {
  const classes = useStyles()

  return (
    <div id="thisisme">
      <Grid container justify="center" alignItems="center">
        <Container>
          <Hero
            title="Aston's Way"
            bodyText="Involving and educating people on autism."
          />
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            direction="row"
          >
            <Grid item sm={7}>
              <Typography className={classes.bodyText}>
                Aston's Way (in collaboration with{" "}
                <a
                  href="https://www.training2care.co.uk/"
                  rel="noopen noreferrer"
                  target="_blank"
                >
                  Training 2 Care
                </a>{" "}
                ) is an innovative new venture by Team Avery Essex and Training
                2 Care, a company providing high quality training to groups and
                businesses. We've partnered with Training 2 Care to use their
                state of the art autism reality experience buses to help educate
                schools and groups on autism. The buses provide a very
                eye-opening experience of living with autism, introducing some
                of the challenges such as sensory overload. Aston then
                supplements this experience with talks and presentations on the
                topic.
              </Typography>
            </Grid>
            <Grid item sm={4} lg={3}>
              {" "}
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  component="img"
                  alt="Team members with the autism reality experience bus"
                  image={Achieve}
                  title="Autism reality experience bus"
                />
                <CardContent>
                  <Typography className={classes.cardCaption}>
                    Team members with one of the autism reality experience
                    buses.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            direction="row-reverse"
          >
            <Grid item sm={7}>
              <Typography className={classes.bodyText}>
                We've previously partnered with{" "}
                <a
                  href="http://www.essex-fire.gov.uk/"
                  rel="noopen noreferrer"
                  target="_blank"
                >
                  Essex Police & Fire Service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.woodlandsschool.org/"
                  rel="noopen noreferrer"
                  target="_blank"
                >
                  Woodlands School
                </a>{" "}
                to provide training days and experiences for the emergency
                services and students. These days were incredibly well received
                and all attendees felt they learnt valuable lessons on autism
                and special educational needs. Read about the emergency services
                experience{" "}
                <a
                  href="http://www.essex-fire.gov.uk/news/Emergency_service_staff_become_autism_aware/#5b472fef62199"
                  rel="noopen noreferrer"
                  target="_blank"
                >
                  here.
                </a>{" "}
                Aston's ultimate dream is for this training to become mandatory
                for all emergency service workers.
              </Typography>
            </Grid>
            <Grid item sm={4} lg={3}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  component="img"
                  alt="Team members with Essex Police"
                  image={SchoolWay}
                  title="Aston's way with Essex Police"
                />
                <CardContent>
                  <Typography className={classes.cardCaption}>
                    Educating Essex police with Aston's Way.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Typography className={classes.bodyText}>
            Are you a school or group and interested in booking Aston's Way? Use
            the form below to submit a booking request. We'll reply back as soon
            as we can! Please note that there is generally a fee for this
            service, though sponsorship can be obtained if your need is great.
            Applications are assessed on a case-by-case basis.
          </Typography>
          <AstonsWayForm />
        </Container>
      </Grid>
    </div>
  )
}
